@import "./src/assets/scss/main.scss";




















































































































































.admin-order-wrapper {
    background: #F5F5F5;
    border-radius: 9px;
    overflow: hidden;
}

.adminOrder {
    position: relative;
    background: #F5F5F5;
    padding-top: 25px;
    padding-bottom: 3px;

    &__header {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        font-family: Montserrat;
        cursor: pointer;

        // margin-bottom: 18px;
        max-width: calc(100% - 34px);
        margin-left: auto;
        margin-right: auto;
    }

    &__header-number {
        font-size: 16px;
        margin-bottom: 17px;
       width: 200px;
        p {
            padding: 0;
            margin: 0;
        }
    }

    &__header-time {
        font-size: 18px;

        font-weight: 700;
        text-align: right;
        width: 33%;
        padding-right: 30px;

font-weight: 600;
font-size: 16px;
line-height: 20px;
/* identical to box height */



color: #FF7728;
    }

    .orange {
        color: $orange;
    }

    &__btn {
        margin-top: 20px;
        padding-bottom: 29px;
    }
}

.showFullOrder {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    cursor: pointer;
}

.g-3,
.gx-3 {
    --bs-gutter-x: .6rem;
}

.toggle {
    border: solid #777777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.opened {
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 14px;
}

.opened .toggle {
    transform: rotate(225deg);
}

.adminOrder__btn {
    display: flex;
    align-items: center;

    b {
        margin-right: 16px;
        font-family: Montserrat;
        margin-left: 16px;
        font-weight: 600;
        font-size: 18px;
    }
}

.adminOrderItem {
    display: flex;
    font-family: Montserrat;
    background: #fff;
    //opacity: .5;
    padding: 15px;
    width: 300px;
    height: 102px;
    border-radius: 9px;
    max-width: 315px;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    &__number {
        width: 88px;
        height: 72px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--orange {
            background: $orange;
        }

        &--blue {
            background: #0041E8;
        }
    }

    &__desc {
        padding-left: 13px;

        b {
            display: block;

        }

        span {
            display: block;
        }

        &--center {
            width: 100%;
            text-align: center;
        }
    }
}
    .cart-item__noimg{
     height: 72px;
    width: 88px !important;

    margin-right: 25px;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
background-size:contain;
      border-radius: 12px;
    
    }
    .adminOrderItem__number{
        img{
            max-width: 88px;
            height: 72px;
        }
    }
    .adminOrder__header-day{
        text-align: left;
    }
